.about-us {
    line-height: 20px;
}

.ul {
    list-style: square;
    font-size: 13px;
    padding: 20px;
}

 h2 {
    line-height: 40px;
 }

h3 {
    margin-top: 20px;
    margin-bottom: 20px;
}

h5 {
    margin-top: 15px;
    margin-bottom: 15px;
}

p {
    margin-top: 10px;
    margin-bottom: 10px;
}