@media screen and (min-width: 1008px) {
    header {
        max-width: 1400px;
        margin: auto;
    }
    
    h3 {
        font-size: 30px;
    }

    .header-mobile {
        display: none;
    }    

    .navbar-desktop {
        display: flex;
        margin: auto;
        justify-content: space-evenly;
        width: 60%;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .container {
        margin-left: 50px;
        margin-right: 50px;
    }

    .argument {
        width: 32%;
    }

    .three-arguments {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .quality {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .quality-width {
        width: 50%;
    }

    .go-green-img {
        width: 40%;
    }

    .quality-text {
        line-height: 25px;
    }

    .banner {
        display: block;
        position: relative;
        width: 100%;
    }

    .banner h2 {
        font-size: 50px;
    }

    .card-category {
        width: 40%;
        text-align: end;
    }

    .space-div {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .banner-content {
        left: -80px;
    }

    .carousel {
        width: 300px !important;
    }

    .argument-cards {
        flex-direction: row;
    }

    .map-google {
        height: 300px;
        width: 400px;
    }

    .bm-burger-button {
        display: none;
    }
}

@media screen and (min-width: 641px) {
    .banner-content {
        left: -40px;
    }

    .carousel-product {
        width: 50%;
        align-items: flex-start;
    }

    .product-text-desc {
        width: 50%;
    }

    .product-item {
        display: flex;
    }
}