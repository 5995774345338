@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap);

/*
HELPERS 
*/
h1 {
	font-size: 30px;
}

h2 {
	font-size: 28px;
	font-weight: bold;
}

h3 {
	font-size: 22px;
	color: #708b65;
	font-weight: bold;
}

h4 {
	font-size: 16px;
	font-weight: bold;
}

h5 {
	font-size: 15px;
	font-weight: bold;
}

p {
	font-size: 13px;
}

li a {
	text-decoration: none;
	color: #030303;
}

a {
    color: #030303;
    text-decoration: none;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.font-bold {
	font-weight: bold;
}

.d-flex {
	display: flex;
}

.quality {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.color-none {
	-webkit-text-fill-color: transparent;
	font-weight: 900;
}

.text-shadow {
	-webkit-text-stroke: 1px #000;
}


.m-5 {
	margin: 5px;
}

.m-10 {
	margin: 10px;
}

.m-50 {
	margin: 50px;
}

.mb-5 {
	margin-bottom: 5px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mt-5 {
	margin-top: 5px;
}

.mt-10 {
	margin-top: 10px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-30 {
	margin-top: 30px;
}

.p-5 {
	padding: 5px;
}

.p-10 {
	padding: 10px;
}

.m-20 {
	margin: 20px;
}

.ml-20 {
	margin-left: 20px;
}

.lh-10 {
	line-height: 10px;
}

.lh-20 {
	line-height: 20px;
}

.d-flex-column {
	display: flex;
	flex-direction: column;
}

.d-flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.d-flex-center-content-start {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.d-flex-column-center {
	display: flex;
	flex-direction: column;
}

.banner h2 {
	font-size: 32px;
}

.action {
	border: 1px solid black;
	padding: 10px;
	text-decoration: none;
	color: #030303;
}

.banner .action-link {
	text-transform: uppercase;
	font-weight: bold;
	color: black;
	font-size: 14px;
}

.banner-content.top {
	justify-content: flex-start;
}

.space-div {
	margin-top: 20px;
	padding-top: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.link-card {
	margin-top: 30px;
}

.flex-center {
	display: flecx;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.flex-center span {
	margin-right: 8px;
}

body {
	font-family: "Montserrat";
}

.navbar-desktop {
	display: none;
}

.header-mobile {
	height: 50px;
	background: rgba(121, 142, 99, 0.18);
	box-shadow: 2px 5px 4px rgb(0 0 0 / 15%);
	display: flex;
	align-items: center;
	width: 100%;
}

.link-breadcrumb {
	text-decoration: none;
	color: #030303;
	font-size: 13px;
}

.header-logo {
	padding-top: 10px;
	height: 90px;
}

.header-div-logo {
	text-align: center;
}

.cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.card-global {
	width: 40%;
	margin: 6px;
	border-radius: 5px;
	padding: 5px;
}

.card-inverse-background-color {
	background: #ffbf37;
	width: 100%;
	height: 100%;
	opacity: 0.2;
}

.card-background-color {
	width: 100%;
	height: 100%;
	z-index: -1;
	position: relative;
	background: #d3d3d35c;
	border-radius: 5px;
}

.card {
	width: 44%;
	height: 230px;
}

.card-background {
	background-size: contain;
	background-repeat: no-repeat;
	width: 100%;
}

.card-inverse {
	background-repeat: no-repeat;
	width: 100%;
	background-position: center;
	background-size: contain;
	height: 120px;
}

.card-inverse-text {
	font-weight: 700;
	font-size: 18px;
	text-align: left;
}
/* 
.card-background-inverse-first {
	background-image: url("./assets/images/250026-1\ brique.png");
}

.card-background-inverse-second {
	background-image: url("./assets/images/250026-1\ brique.png");
} */

.card-background-inverse {
	background-size: contain;
	background-repeat: no-repeat;
	width: 60%;
}

.card-informations {
	padding-left: 20px;
	padding-right: 20px;
	line-height: 20px;
}

.card-background-second {
	background-image: url("./assets/images/carousel.png");
}

.container {
	padding: 5px;
}

.menu-items {
	display: none;
}

.banner-title {
	background: #f8f8f8;
	height: 80px;
	display: flex;
	justify-content: start;
	align-items: center;
	padding: 20px;
}

.banner-title-text {
	margin-left: 5px;
	font-size: 25px;
}

.carousel-photo {
	display: block;
	width: 100%;
	height: auto;
}

.argument {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 5px;
	/* background: #eaeaea38; */
}

.argument-img {
	width: 35px;
	height: auto;
}

.argument-title {
	font-size: 14px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin: 0px;
}

.argument-phrase {
	text-align: center;
	font-size: 12px;
	padding-left: 40px;
	padding-right: 40px;
	line-height: 20px;
}

.banner {
	display: block;
	position: relative;
}

.banner-content {
	position: absolute;
	top: 0;
	left: -20px;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: flex-start;
	padding: 15px;
	z-index: 1;
}

.banner-content.left {
	align-items: flex-start;
	padding-left: 5%;
}

#more-button-1 {
	border-style: none;
	background: none;
	font: 16px Serif;
	color: blue;
	margin: 0 0 10px 0;
}

#grow input:checked {
	color: red;
}

#more-button-1:hover {
	color: black;
}

#grow {
	-moz-transition: height 0.5s;
	-ms-transition: height 0.5s;
	-o-transition: height 0.5s;
	-webkit-transition: height 0.5s;
	transition: height 0.5s;
	height: 0;
	overflow: hidden;
}

.breadcrumbs {
	display: flex;
	margin-bottom: 30px;
	padding: 20px;
}

.arrow-breadcrumbs {
	width: 10px;
	height: 100%;
	padding-top: 3px;
}

.links-breadcrumbs {
	font-size: 13px;
	text-decoration: none;
	color: black;
}

.about-us-text-green {
	color: #708b65;
	font-weight: bold;
	font-size: 15px;
	letter-spacing: 2px;
}

.text-signature {
	font-size: 10px;
}

.contact-icon {
	width: 50px;
}

.label-form {
	font-size: 13px;
}

.input-form {
	height: 20px;
	border: 0.4px solid lightgrey;
	border-radius: 5px;
}

.textarea-form {
	border: 0.4px solid lightgrey;
	border-radius: 5px;
}

.button-submit {
	color: #f3f3f3;
	width: 100%;
	height: 30px;
	background: #708b65;
	border: 0px;
	border-radius: 10px;
}

.burger-menu {
	height: 25px;
	margin-left: 5px;
}

.carousel .thumb.selected {
	border: 1px solid #333 !important;
}

.product-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	/* background: #f8f8f8; */
}

.product-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
}

.three-arguments {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 40px;
}

.go-green-img {
	width: 80%;
	margin-top: 30px;
}

.quality-text {
	margin-top: 30px;
	line-height: 20px;
}

.img-card-category {
	height: 360px;
}

.card-category {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px;
}

.carousel {
	width: 98% !important;
}

.images-dimension {
	margin-top: 60px;
	display: flex;
    flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}

.div-image-dimension {
	display: flex;
	justify-content: center;
	align-items: center;
}

.image-dimension {
	height: 250px;
	margin-bottom: 20px;
}

.text-green {
	color: #708b65;
}

.map-google {
	background-image: url('./assets/images/google-maps.png');
    height: 200px;
    background-position: center;
    background-size: cover;
}

.footer {
	line-height: 21px;
}

.page-footer {
	padding: 20px;
}

.footer-links {
	font-size: 12px;
}