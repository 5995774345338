footer {
	background: rgba(121, 142, 99, 0.18);
	position: absolute;
	left: 0;
	right: 0;
	margin-top: 40px;
}

.copyright {
    font-size: 9px;
}