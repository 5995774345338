
.carousel-product {
	display: flex;
	justify-content: center;
	align-items: center;
}

.product-item {
	padding-left: 15px;
	padding-right: 15px;
}

.argument-card {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 20px;
}

.argument-picture {
	width: 100px;
}

.badge-color {
	margin: 5px;
}

.badge {
	height: 25px;
	width: 25px;
	margin-right: 5px;
	margin-bottom: 5px;
	margin-top: 5px;
	border-radius: 50%;
	display: inline-block;
}

.vert-kiwi {
	background: #abb033 !important;
}

.bleu-metal {
	background: #3a8eba !important;
}

.anthracite {
	background: #303030 !important;
}

.brique {
	background: #842e1b !important;
}

.marine {
	background: #000080 !important;
}

.rouge {
	background: rgb(218, 50, 50) !important;
}

.noir {
	background: #030303 !important;
}

.product-colors {
	margin-top: 10px;
}

.carousel-status {
	display: none;
}

.story {
	line-height: 30px;
	margin-top: 10px;
}

.features {
	margin-top: 30px;
	margin-bottom: 30px;
	line-height: 30px;
	padding-right: 15px;
}

.argument-card {
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
	width: 33%;
}

.product-name {
	margin-top: 2px;
	margin-bottom: 13px;
}

.argument-cards {
	display: flex;
    flex-wrap: wrap;
    justify-content: space-around;;
    align-items: center;
	flex-direction: column;
}


.th {
	width: 20%;
	border: 1px solid #333;
}

td {
	text-align: center;
}

table,
td {
    border: 1px solid #333;
}
